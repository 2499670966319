import React from "react";
import { rhythm } from "../utils/typography";
import "./feature-card.scss";

const ChatBubble = ({ side = "left", message }) => {
  return <div className={`chat-bubble chat-bubble--${side}`}>{message}</div>;
};

//const formatIdx = (idx) => (idx < 10 ? `0${idx}` : "" + idx);
const formatIdx = (idx) => idx;

export const FeatureCard = (props) => {
  const children = React.Children.toArray(props.children);
  const title = children.find((child) => child.type === FeatureCardTitle);
  const copy = children.find((child) => child.type === FeatureCardCopy);
  const image = children.find((child) => child.type === FeatureCardImage);
  const idx = props.idx;
  const style = props.style || {};
  const hasIdx = typeof idx === "number";
  const isEven = (idx || 0) % 2;
  return (
    <div
      className="feature-card"
      style={{ marginTop: 0, marginBottom: rhythm(1), ...style }}
    >
      <div className="feature-card__copy">
        {title && <h3 className="feature-card__title">{title}</h3>}
        {/*{title && hasIdx && (*/}
        {/*  <div*/}
        {/*    className="feature-card__title-with-index"*/}
        {/*    style={{ marginBottom: rhythm(1 / 2) }}*/}
        {/*  >*/}
        {/*    <div className="feature-card__index">{formatIdx(idx)}</div>*/}
        {/*    <h3 className="feature-card__title">{title}</h3>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{title && !hasIdx && (*/}
        {/*  <h3*/}
        {/*    className="feature-card__title"*/}
        {/*    style={{ marginBottom: rhythm(1 / 2) }}*/}
        {/*  >*/}
        {/*    {title}*/}
        {/*  </h3>*/}
        {/*)}*/}
        <div className="feature-card__copy-content">{copy}</div>
        {/* <div className="hide-xs">
          {props.chatBubbleText && (
            <ChatBubble
              message={props.chatBubbleText}
              side={isEven ? "left" : "right"}
            />
          )}
        </div> */}
      </div>
      <figure className="feature-card__image">{image}</figure>
      {/* <div className="hide-lg-xs">
        {props.chatBubbleText && (
          <ChatBubble
            message={props.chatBubbleText}
            side={isEven ? "left" : "right"}
          />
        )}
      </div> */}
    </div>
  );
};

export const FeatureCardTitle = (props) => props.children;
export const FeatureCardCopy = (props) => props.children;
export const FeatureCardImage = (props) => props.children;
