import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";
import {
  FeatureCard,
  FeatureCardCopy,
  FeatureCardImage,
  FeatureCardTitle,
} from "../components/feature-card";
import "./benefits.scss";

class Page extends React.Component {
  state = {
    productName: "Remeet",
  };

  componentDidMount() {}

  render() {
    return (
      <Layout withMaxWidth={false}>
        <SEO title={"Benefits"} />

        <section
          className="benefits-page benefits-page--container"
          style={{
            paddingTop: rhythm(2.5),
            paddingBottom: rhythm(2.5),
            marginBottom: rhythm(1),
          }}
        >
          <div
            className="benefits-page__heading"
            style={{ marginBottom: rhythm(2) }}
          >
            <h2 className="benefits-page__heading-title">
              {this.state.productName} is a fundamentally new approach to
              communication for distributed teams
            </h2>
            <p className="benefits-page__heading-p">
              Meetings as we know them are the product of an environment that
              assumes workers are in the same physical location and have the
              same 9 to 5 schedule. {this.state.productName} transforms remote
              meetings to fit a modern distributed work culture.
            </p>
          </div>
          <div className="benefits-page__benefits-list">
            <FeatureCard idx={1}>
              <FeatureCardTitle>
                All your topics at once — no more meetings scattered throughout
                the day
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Sync hour is the new daily meeting routine for your team:
                  one (or more, if you must) hours a day dedicated to short
                  <strong>topics</strong> with your colleagues. This
                  makes distractions caused by meetings and scheduling a thing
                  of the past, and boosts team productivity.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <video
                  style={{ maxWidth: "100%" }}
                  autoPlay
                  playsInline
                  loop
                  muted
                  src="/static/condensing.mp4"
                ></video>
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={2}>
              <FeatureCardTitle>
                All your topics scheduled (and rescheduled) automatically
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Stop wasting time juggling your calendar. Just tell{" "}
                  {this.state.productName} <strong>what</strong> you need to
                  talk about, <strong>who</strong> you need to talk to, and{" "}
                  <strong>how long</strong> the conversation needs to be (hint:
                  it’s shorter than you think). {this.state.productName} does
                  the rest.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <video
                  style={{ maxWidth: "100%" }}
                  autoPlay
                  playsInline
                  loop
                  muted
                  src="/static/scheduling.mp4"
                ></video>
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={3}>
              <FeatureCardTitle>Topics don’t compete with work</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Finding a time that works for everyone isn’t easy, especially
                  across time zones.
                </p>
                <p>
                  {this.state.productName}’s dynamic scheduling algorithm makes
                  topics compete with each other for the limited hours allocated
                  for all your topics. As a result, everyone gets a predictable
                  amount of uninterrupted time to focus on tasks.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/balance-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={4}>
              <FeatureCardTitle>Short single-topic meetings</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  With no manual scheduling, you no longer need meetings that
                  cover multiple topics. Every topic becomes its own{" "}
                  <strong>short and focused</strong> meeting. As a result the
                  median duration of meetings on {this.state.productName} is 10
                  minutes, with many as short as 5 minutes.
                </p>
                <p>
                  You’ll never have to write another meeting agenda (that will
                  end up being ignored) again, just sit back and watch your
                  automated calendar become your personalized agenda.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/single-topic-meets-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={5}>
              <FeatureCardTitle>Mindful experience</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Traditional multi-topic remote meetings invite multitasking;
                  people don’t focus on discussions that are irrelevant to them.
                  This creates a bad habit of constantly doing something else
                  during meetings — as a result it makes it hard for our brains
                  to focus even when it’s necessary. This causes anxiety,
                  meeting fatigue, and dissatisfaction with synchronous
                  communication.
                </p>
                <p>
                  By eliminating multi-topic meetings, {this.state.productName}{" "}
                  helps everyone stay engaged and enjoy every meeting (really).
                  This level of engagement leads to high levels of meeting
                  productivity and amazing team results.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/mindfull-experience-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={6}>
              <FeatureCardTitle>Flow through topics</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  A flow state is when your brain is at its peak level of
                  productivity and you experience happiness.
                </p>
                <p>
                  With a clearly separated calendar you are a lot more likely to
                  experience flow during working hours and even during sync hours —
                  making your workday both more satisfying and more productive.
                </p>
                <p>
                  Our users routinely flow through 4-6 topics in a single sync hour
                  hour in a stress-free and satisfying experience.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/flow-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={7}>
              <FeatureCardTitle>Everyone comes prepared</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Forget about meetings with people coming unprepared, or not
                  realizing they needed to prepare. When you create a topic, you
                  can indicate which participants need to prepare and it won’t
                  be scheduled until they do.
                </p>
                <p>
                  If you are familiar with the Silent Start technique from
                  Amazon, this is the same idea, taken further and made
                  asynchronous.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <video
                  style={{ maxWidth: "100%" }}
                  autoPlay
                  playsInline
                  loop
                  muted
                  src="/static/preparing.mp4"
                ></video>
              </FeatureCardImage>
            </FeatureCard>
            <FeatureCard idx={8}>
              <FeatureCardTitle>
                Higher transparency with fewer participants
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  There is a tendency to over-invite people to meetings to
                  increase transparency and inclusion, but the result is
                  disastrous for both meetings <strong>and</strong>{" "}
                  productivity. With {this.state.productName}, topics are
                  transparent by default — recorded and shared in Slack channels
                  with notes (unless users make the topic sensitive). There is no
                  need to invite anyone as a courtesy or ‘just in case’ — they
                  can participate in every discussion asynchronously.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/transparency-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={9}>
              <FeatureCardTitle>
                More inclusive team environment
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  {this.state.productName} practically eliminates the role of
                  meeting organizer, and democratizes access to the calendar.
                  The calendar is no longer pre-filled by immovable weekly
                  meetings with their own agendas. With {this.state.productName}{" "}
                  anyone can schedule a topic with anyone else regardless of job
                  title or organizational status — it just happens and our users
                  love it.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/inclusive-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={10}>
              <FeatureCardTitle>
                The Maker’s & Manager’s schedule conflict is finally resolved
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  {this.state.productName} is the first solution to address the
                  fundamental conflict between what Paul Graham calls the
                  maker’s schedule and the manager’s schedule. With{" "}
                  {this.state.productName} it’s simple: makers accept just a
                  single sync hour a day, and managers can accept two, three or
                  more.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/maker-manager-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>
          </div>
          <div
            className="benefits-page__breakout"
            style={{
              paddingTop: rhythm(2.5),
              paddingBottom: rhythm(2.5),
            }}
          >
            <h2
              className="benefits-page__breakout-text"
              style={{
                marginTop: rhythm(2.5),
                marginBottom: rhythm(2.5),
              }}
            >
              {this.state.productName} provides a team meeting experience that’s
              10x more efficient than anything that can be accomplished with
              existing tools.
            </h2>
          </div>
          <div className="benefits-page__benefits-list">
            <FeatureCard idx={11}>
              <FeatureCardTitle>
                No need to ask for availability or permission to schedule a
                meeting
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Teams are built on trust, and we trust every individual to do
                  what they think is best. There’s no need for the
                  Accept/Tentative/Decline buttons for every topic. With{" "}
                  {this.state.productName}, people accept sync hours, and
                  topics just happen when someone needs them.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/no-need-to-ask-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={12}>
              <FeatureCardTitle>
                Forget about scheduling conflicts and no-shows
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  {this.state.productName}’s scheduler guarantees no scheduling
                  conflicts so nobody gets double-booked. Since sync hours are at
                  the same time every day, it soon becomes second-nature and
                  nobody forgets.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <video
                  style={{ maxWidth: "100%" }}
                  autoPlay
                  playsInline
                  loop
                  muted
                  src="/static/flowing.mp4"
                ></video>
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={13}>
              <FeatureCardTitle>
                No more too early or too late meetings
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  If your team is globally distributed, then the “sweet spot”
                  where the time zones intersect is probably congested and full
                  of the regular, recurring meetings, putting every other
                  meeting at a bad time for someone. Replace them with sync hours,
                  and you will never have to stay up late or wake up early to be
                  able to meet with your colleagues.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/no-meeting-zone-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={14}>
              <FeatureCardTitle>Slow down the pace of time</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  After a month of using {this.state.productName}, our users say
                  they accomplish more in a 10 minute meeting than they used to in
                  traditional 30-60 minute meetings. That’s a 5x productivity
                  boost!
                </p>
                <p>
                  {this.state.productName} also helps everyone to be punctual.
                  Instant transitions from one topic to another helps topics start
                  on time, and hard stop indicators and timers help topics end on
                  time. Showing who’s already waiting in the next meeting creates
                  positive peer pressure to be on time.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/slow-down-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={15}>
              <FeatureCardTitle>
                Required attendees are really required, and optional attendees
                are really optional
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  With traditional meetings, required vs optional attendees is a
                  distinction that very few people understand, and many ignore.
                  With {this.state.productName}, topics are scheduled only during
                  sync hours when required participants have already confirmed
                  availability. Optional attendees are clearly indicated, and
                  only invited when they have no other topics to attend.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/fomo-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={16}>
              <FeatureCardTitle>Integrated note taking</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Our users love that {this.state.productName} encourages
                  everyone to capture notes and action items by making the
                  collaborative document a central piece of the meeting
                  experience — right where it belongs. Notes are stored on{" "}
                  {this.state.productName} and posted on Slack.
                </p>
                <p>Every recording is also automatically transcribed and summarized. </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/notes-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={17}>
              <FeatureCardTitle>
                Recordings that people actually watch
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Traditional meetings are long, and often recorded but rarely
                  watched. Most recordings on {this.state.productName} are
                  between 5 and 15 minutes of very engaged discussion. 40% of{" "}
                  {this.state.productName} users watch at least one recording a
                  week — something that just doesn’t happen with traditional
                  remote meeting recordings.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/watching-recordings-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={18}>
              <FeatureCardTitle>
                No meeting is lost with {this.state.productName}
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  No need to invite people to listen in just so they can be{" "}
                  <strong>in the loop</strong>, and no need to schedule another
                  meeting just to propagate knowledge. {this.state.productName}{" "}
                  <strong>shares the recording</strong> and voice transcription
                  with everyone on the Slack channel so the content can be
                  easily accessed by anyone who needs it.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/no-meeting-lost-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={19}>
              <FeatureCardTitle>Topics are searchable</FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Now everyone can search previous topics before scheduling a new
                  one. You can search for a topic title, notes, or transcription,
                  and filter by participants.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/search-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={20}>
              <FeatureCardTitle>
                Random small talks to boost morale and innovation
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  One of the things we miss out on working from home is the
                  random, informal, (yet sometimes productive and inspirational)
                  social interactions with colleagues. {this.state.productName}{" "}
                  uses gaps between topics to schedule random “small talk”
                  sessions with colleagues to socially bond and boost
                  innovation.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <a
                  href="https://twitter.com/paulg/status/1270622556098355201"
                  style={{ backgroundImage: "none" }}
                >
                  <img
                    src={require("../../../images/main/pg-tweet-optimized.jpeg")}
                    alt="One of the biggest things lost in remote work is chance meetings. These are very important, but hard to quantify. If you measure productivity on individual projects, everything will seem fine. Yet when you read stories of how things happened, chance meetings were often crucial."
                  />
                </a>
              </FeatureCardImage>
            </FeatureCard>

            <FeatureCard idx={21}>
              <FeatureCardTitle>
                Optimize meetings at organization scale
              </FeatureCardTitle>
              <FeatureCardCopy>
                <p>
                  Meetings are a common bottleneck in large organizations.
                  {this.state.productName} eliminates many factors that lead to
                  bottlenecks and gives you tools to analyze topic backlog,
                  waiting time report, and network charts.
                </p>
              </FeatureCardCopy>
              <FeatureCardImage>
                <img
                  src={require("../../../images/main/charts-optimized.jpeg")}
                  alt=""
                />
              </FeatureCardImage>
            </FeatureCard>
          </div>
          <div className="benefits-page__breakout benefits-page__breakout-text--white">
            <h2
              className="benefits-page__breakout-text"
              style={{
                marginTop: rhythm(2.5),
                marginBottom: rhythm(1),
              }}
            >
              If more than 30% of your time is consumed by meetings, plan on
              getting 6+ hours back every week
            </h2>
            <a
              href="/getstarted/"
              className="request-a-demo mdc-button mdc-button--unelevated"
            >
              Get Started
            </a>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
